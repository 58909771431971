<template>
    <div class="home">
        <cHeader @search="search($event)" :keyword="queryParams.keyword"></cHeader>
        <cMenu @changeFirstCate="changeFirstCategory($event)" @changeSecondCate="changeSecondCate(arguments)"></cMenu>
        <div class="query-screen">
            <div class="query-criteria">
                <div class="select-classify ng-star-inserted" v-if="queryParams.keyword">
                    <div class="label">搜索条件：</div>
                    <ul class="search-criteria-wrap">
                        <li class="ng-star-inserted">关键词：{{queryParams.keyword}}
                            <a-icon type="close" @click="clearKeyword"/>
                        </li>
                    </ul>
                    <div class="empty-btn">
                        <button class="ant-btn"><span class="ng-star-inserted"
                                                                                    @click="clearKeyword">清空条件</span>
                        </button>
                    </div>
                </div>
                <a-form layout="inline" v-if="queryParams.cate1_id > 0">
                    <div class="select-classify">
                        <a-form-item>
                            <label slot="label" class="label">商品分类</label>
                            <a-select class="ant-select-box" :value="categoryList[queryParams.cate1_idx].title"
                                      placeholder="请选择" style="width: 120px" @change="changeFirstCategory"
                                      :default-active-first-option="false">
                                <a-select-option v-for="(item, idx) in categoryList" :key="idx" :value="idx">
                                    {{item.title}}
                                </a-select-option>
                            </a-select>
                            <a-select class="ant-select-box" v-if="queryParams.cate2_id > 0"
                                      :value="categoryList[queryParams.cate1_idx]['children'][queryParams.cate2_idx].title"
                                      placeholder="请选择" style="width: 120px" @change="changeSecondCategory"
                                      :default-active-first-option="false">
                                <a-select-option v-for="(item, idx) in categoryList[queryParams.cate1_idx]['children']"
                                                 :key="idx" :value="idx">
                                    {{item.title}}
                                </a-select-option>
                            </a-select>
                        </a-form-item>
                        <a-form-item>
                            <a-button @click="clearCateSelected">清空分类</a-button>
                        </a-form-item>
                    </div>
                </a-form>
                <form action="" class="ant-form">
                    <div class="list-warp" v-if="Object.keys(showCategories).length > 0">
                        <div class="title">分类</div>
                        <div class="ant-radio-group">
                            <label class="ant-radio-button-wrapper" v-for="(item, idx) in showCategories" :key="idx"
                                   @click="changeCategory(idx, item.pid)">{{item.title}}</label>
                        </div>
                    </div>
                </form>

                <div class="other-query-first">
                    <div class="left">
                        <ul>
                            <li :class="queryParams.sortType == 1 ? 'active' : ''" @click="changeSortType(1)">综合</li>
                            <li :class="queryParams.sortType == 2 ? 'active' : ''" @click="changeSortType(2)">销量<img
                                    src="../assets/img/sales-arrow.png" class="sales-arrow"></li>
                        </ul>
                        <div class="checkbox-wrapper">
                            <a-checkbox-group @change="changeQueryGoodsState" :options="goodsState"></a-checkbox-group>
                        </div>
                    </div>
                    <div class="right">
                        <div class="pagination">
                            <div class="total">{{total}}+种商品</div>
                            <button class="ant-btn ant-btn-icon-only" @click="prevPage">
                                <a-icon type="left"/>
                            </button>
                            <span>{{queryParams.page}}/{{ pages }}</span>
                            <button class="ant-btn ant-btn-icon-only" @click="nextPage">
                                <a-icon type="right"/>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="other-query-second">
                    <ul class="left">
                        <li>
                            <div class="label">分销价¥：</div>
                            <div class="minimum"><input v-model="queryParams.base_price1" placeholder="最低"
                                                        class="ant-input ng-untouched ng-pristine ng-valid"></div>
                            <div class="line"></div>
                            <div class="supreme"><input v-model="queryParams.base_price2" placeholder="最高"
                                                        class="ant-input ng-untouched ng-pristine ng-valid"></div>
                            <button class="ant-btn" @click="searchGoods()"><span class="ng-star-inserted">确定</span></button>
                            <button class="empty ant-btn" @click="clearBasePrice"><span class="ng-star-inserted">清空</span>
                            </button>
                        </li>
                        <li>
                            <div class="label">毛利率%：</div>
                            <div class="minimum"><input v-model="queryParams.grossProfitRate1" placeholder="最低" class="ant-input ng-untouched ng-pristine ng-valid"></div>
                            <div class="line"></div>
                            <div class="supreme"><input v-model="queryParams.grossProfitRate2" placeholder="最高" class="ant-input ng-untouched ng-pristine ng-valid"></div>
                            <button class="ant-btn" @click="searchGoods()"><span class="ng-star-inserted">确定</span></button>
                            <button class="empty ant-btn" @click="clearGrossProfitRate"><span class="ng-star-inserted">清空</span>
                            </button>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="goods-list-wrap">
            <div class="ant-list-grid ant-list ant-list-split">
                <a-spin :spinning="spinning">
                    <div class="ant-row ng-star-inserted" style="margin-left: -4px; margin-right: -4px;">
                        <div class="ant-col ant-col-xs-8 ant-col-sm-8 ant-col-md-8 ant-col-lg-8 ng-star-inserted"
                             style="padding-left: 4px; padding-right: 4px;" v-for="item in goodsList" :key="item.id">
                            <div class="ant-list-item ng-star-inserted">

                                <a-card>
                                    <div class="ant-card-meta ng-star-inserted">
                                        <div class="ant-card-meta-detail ng-star-inserted">
                                            <div class="ant-card-meta-description ng-star-inserted">
                                                <a
                                                        class="card-meta-main ng-star-inserted"
                                                        @click="$jumpTo('/goods/'+item.id+'/type/'+item.type)">
                                                    <div class="good-img">
                                                        <img :src="item.logo">
                                                    </div>
                                                    <div class="good-price"><span
                                                    >¥</span><strong
                                                    >{{item.base_price}}</strong></div>
                                                    <div class="gross-profit"> 毛利率{{parseFloat((item.suggest_price -
                                                        item.base_price) / item.suggest_price *
                                                        100).toFixed(2)}}%
                                                    </div>
                                                    <div class="good-desc">
                                                        {{ item.title }}
                                                    </div>
                                                    <!--                                                        <div class="good-other">-->
                                                    <!--                                                            <div class="left">全部商品</div>-->
                                                    <!--                                                        </div>-->
                                                </a></div>
                                        </div>
                                    </div>
                                    <ul slot="actions">
                                        <li class="ng-star-inserted" style="width: 100%;">
                                                <span>
                                                    <button class="action-box action-active" v-if="item.in_lib"
                                                            disabled>
                                                        <img src="../assets/img/lock-white.png">
                                                        <span>已加入我的商品库</span>
                                                    </button>
                                                    <button class="action-box " @click="addToMyLib(item.id, item.type)" v-else>
                                                        <img src="../assets/img/lock_red.png">
                                                        <span>加入我的商品库</span>
                                                    </button>
                                                </span>
                                        </li>
                                    </ul>
                                </a-card>
                            </div>
                        </div>
                    </div>
                </a-spin>
            </div>
            <div class="pagination">
                <a-pagination show-quick-jumper :current="queryParams.page" :pageSize="queryParams.pageSize"
                              :total="total" @change="changePage"/>
            </div>
        </div>
        <cFooter></cFooter>
    </div>
</template>

<script>
    // @ is an alias to /src

    import cHeader from '../component/header';
    import cFooter from '../component/footer';
    import cMenu from '../component/menu';
    import service from "../../utils/request";

    export default {
        name: 'Home',
        components: {
            cHeader,
            cFooter,
            cMenu
        },
        data() {
            return {
                spinning: false,
                headerWarpActive: '',
                buyerCenterActive: '',
                checkBoxSelected: [],
                priceSort: 0,
                showPickGoods: false,
                goodsList: [],
                queryParams: {
                    keyword: '',
                    cate1_id: 0,
                    cate1_idx: 0,
                    cate2_id: 0,
                    cate2_idx: 0,
                    page: 1,
                    pageSize: 20,
                    sortType: 1,
                    priceSort: 0,
                    goodsState: [],
                    grossProfitRate1: '',
                    grossProfitRate2: '',
                    base_price1: '',
                    base_price2: '',
                },
                goodsState: [
                    {label: '在售', value: 'onSale'},
                    {label: '有货', value: 'hasStock'},
                    {label: '包邮', value: 'pinkage'},
                ],
                total: 0,
                pages: 0,
                categoryList: [],
                showCategories: [],
            }
        },
        created() {
            this.getIndexData();
        },
        methods: {
            clearBasePrice(){
                this.queryParams.base_price1 = this.queryParams.base_price2 = '';
                this.queryParams.page = 1;
                this.getGoodsList();
            },
            clearGrossProfitRate(){
                this.queryParams.grossProfitRate1 = this.queryParams.grossProfitRate2 = '';
                this.queryParams.page = 1;
                this.getGoodsList();
            },
            async getIndexData() {
                await this.getCategoryTree();

                let params = this.$route.params;
                this.queryParams.keyword = params.keyword;
                if (params.firstCate) {
                    this.changeFirstCategory(params.firstCate)
                } else if (params.subCate) {
                    this.changeSecondCate(params.subCate)
                } else {
                    this.getGoodsList();
                }
            },
            searchGoods(){
                this.queryParams.page = 1;
                this.getGoodsList();
            },
            search(e) {
                this.queryParams.keyword = e;
                this.queryParams.page = 1;
                this.getGoodsList();
            },
            clearKeyword() {
                this.queryParams.keyword = '';
                this.queryParams.page = 1;
                this.getGoodsList();
            },
            changeQueryGoodsState(e) {
                this.queryParams.goodsState = e;
                this.queryParams.page = 1;
                this.getGoodsList();
            },
            changeSecondCate(e) {
                let idx = parseInt(e[0]);
                let subIdx = e[1];
                this.queryParams.page = 1;
                this.queryParams.cate1_id = this.categoryList[idx].id;
                this.queryParams.cate1_idx = idx;
                this.showCategories = this.categoryList[idx]['children'];
                this.changeCategory(subIdx, this.queryParams.cate1_id);
            },
            changeFirstCategory(idx) {
                this.queryParams.page = 1;
                this.queryParams.cate1_id = this.categoryList[idx].id;
                this.queryParams.cate1_idx = idx;
                this.queryParams.cate2_id = 0;
                this.queryParams.cate2_idx = 0;
                this.showCategories = this.categoryList[idx]['children'];
                this.getGoodsList();
            },
            changeSecondCategory(subIdx) {
                this.queryParams.page = 1;
                this.queryParams.cate2_id = this.categoryList[this.queryParams.cate1_id]['children'][subIdx].id;
                this.queryParams.cate2_idx = subIdx;

                this.showCategories = [];
                this.getGoodsList();
            },
            changeCategory(idx, pid) {
                console.log('changeCategory:', idx, pid)
                if (pid > 0) {
                    this.queryParams.cate2_idx = idx;
                    this.queryParams.cate2_id = this.showCategories[idx].id;
                    console.log('cate2_id:', this.queryParams.cate2_id, 'cate2_idx:', this.queryParams.cate2_idx);
                    this.showCategories = [];
                } else {
                    this.queryParams.cate1_id = this.showCategories[idx].id;
                    this.queryParams.cate1_idx = idx;
                    this.showCategories = this.categoryList[idx]['children'];
                }
                this.queryParams.page = 1;
                this.getGoodsList();
            },
            clearCateSelected() {
                this.queryParams.cate1_id = 0;
                this.queryParams.cate1_idx = 0;
                this.queryParams.cate2_id = 0;
                this.queryParams.cate2_idx = 0;
                this.showCategories = this.categoryList;
                this.queryParams.page = 1;
                this.getGoodsList();
            },
            addCheckBoxClass(className) {
                let idx = this.checkBoxSelected.indexOf(className);
                if (idx >= 0) {
                    this.checkBoxSelected.splice(idx, 1);
                } else {
                    this.checkBoxSelected.push(className);
                }
            },
            addToMyLib(spuId, goodsType) {
                service.post(service.uri.goods.addToMyLib, {spu_id: spuId, goods_type: goodsType}).then(res => {
                    if (res.code == 200) {
                        this.getGoodsList();
                    }
                });
            },
            changeSortType(sortType) {
                this.queryParams.sortType = sortType;
                this.queryParams.priceSort = 0;
                this.getGoodsList();
            },
            togglePriceSort() {

                this.queryParams.sortType = 3;
                if (this.queryParams.priceSort == 0) {
                    this.queryParams.priceSort = 2;
                }
                this.queryParams.priceSort = 3 - this.queryParams.priceSort;
                this.getGoodsList();
            },
            toggleShowPickGoods() {
                let showPickGoods = this.showPickGoods;
                this.showPickGoods = !showPickGoods;
                console.log(this.showPickGoods)
            },
            getGoodsList() {
                this.spinning = true;
                service.post(service.uri.goods.getList, this.queryParams).then(res => {
                    this.spinning = false;
                    if (res.code == 200) {
                        this.goodsList = res.data.list;
                        this.total = res.data.total;
                        this.pages = res.data.pages;
                    }
                });
            },
            changePage(e) {
                this.queryParams.page = e;
                this.getGoodsList();
            },
            nextPage() {
                this.queryParams.page++;
                this.getGoodsList();
            },
            prevPage() {
                if (this.queryParams.page <= 1) return false;
                this.queryParams.page--;
                this.getGoodsList();
            },
            getCategoryTree() {
                console.log('getCategoryTree out')
                return new Promise((resolve, reject) => {
                    service.post(service.uri.goods.getCategoryTree).then(res => {
                        console.log('getCategoryTree service:', res)
                        if (res.code != 200) {
                            reject();
                        }

                        this.categoryList = res.data.list;
                        console.log('getCategoryTree res:', this.categoryList)
                        this.showCategories = this.categoryList;
                        resolve(res.data.list);
                    })
                });

            },
        }
    }
</script>

<style lang="scss">
    @import "../assets/css/common";

    .select-classify {
        .ant-select-selection-selected-value {
            font-size: 12px;
            color: rgb(153, 153, 153);
        }

        .ant-form-item {
            label {
                font-size: 12px;
            }
        }

        .label {
            min-width: 78px;
            line-height: 40px;
            padding-left: 18px;
            color: #666;
            font-size: 12px;
        }

        .search-criteria-wrap {
            min-height: 40px;
            max-height: 120px;
            font-size: 12px;
            color: #999;
            margin: 0 10px;
            display: flex;
            flex-flow: wrap;

            li {
                border: 1px solid #ededed;
                height: 32px;
                line-height: 30px;
                padding: 0 0 0 8px;
                display: flex;
                margin: 4px 10px 4px 0;

                .anticon {
                    display: block;
                    color: #999;
                    width: 30px;
                    height: 30px;
                    margin-left: 8px;
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }

    .ant-list {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, .65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
    }

    .ant-card {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        color: rgba(0, 0, 0, .65);
        font-size: 14px;
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: "tnum";
        position: relative;
        background: #fff;
        border-radius: 2px;
        transition: all .3s;
    }

    .ant-card-actions:after, .ant-card-actions:before {
        display: table;
        content: "";
    }

    .ant-card-actions:after {
        clear: both;
    }

    .home {
        background: #fff;
        min-height: 1000px;

        ol, ul, dl {
            margin: 0;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            position: absolute;
            top: -1px;
            left: -1px;
            display: block;
            box-sizing: content-box;
            width: 1px;
            height: 100%;
            padding: 1px 0;
            background: none;
            transition: background-color 0.3s;
            content: '';
        }

        .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
            padding: 0;
            overflow-wrap: break-word;
        }

        .query-screen {
            width: 100%;

            .query-criteria {
                max-width: 1200px;
                margin: 0 auto;
                padding: 10px 0;
                font-weight: 400;
                color: #323238;
                font-size: 12px;

                .select-classify {
                    display: flex;
                    margin: 10px 0;

                    .ant-select-box {
                        margin: 0 10px;
                    }

                    .ant-btn {
                        height: 32px;
                        padding: 8px 9px;
                        margin: 4px 0;
                        font-size: 12px;
                        color: #ccabab;
                        border: none;
                        background: linear-gradient(180deg, #fff, #e7e7e7);
                    }
                }

                .ant-form {
                    box-sizing: border-box;
                    margin: 0;
                    padding: 0;
                    color: rgba(0, 0, 0, .65);
                    font-size: 14px;
                    font-variant: tabular-nums;
                    line-height: 1.5715;
                    list-style: none;
                    font-feature-settings: "tnum";
                }

                .list-warp {
                    display: flex;
                    border: 1px solid #ededed;
                    margin-top: -1px;
                    width: 100%;

                    .title {
                        font-size: 12px;
                        line-height: 40px;
                        min-width: 90px;
                        text-align: center;
                        color: #666;
                        background: #f7f7f7;
                    }

                    .ant-radio-group {
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                        color: rgba(0, 0, 0, .65);
                        font-size: 14px;
                        font-variant: tabular-nums;
                        list-style: none;
                        font-feature-settings: "tnum";
                        display: inline-block;
                        line-height: unset;

                        .ant-radio-button-wrapper {
                            padding: 0 24px;
                            height: 40px;
                            border: none;
                            line-height: 40px;
                            color: #323238;
                            font-size: 12px;
                        }

                        .ant-radio-button-wrapper:hover {
                            color: $defaultColor;
                        }
                    }
                }

                .other-query-first {
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid #ededed;
                    width: 100%;
                    background: #f4f4f4;
                    margin-top: 20px;
                    font-size: 12px;
                    color: #323238;

                    .left, .right {
                        display: flex;
                    }

                    ul {
                        display: flex;
                        cursor: pointer;

                        li {
                            height: 40px;
                            text-align: center;
                            min-width: 70px;
                            border-right: 1px solid #ededed;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }

                        li.active {
                            color: $defaultColor;
                        }
                    }

                    .ant-table {
                        box-sizing: border-box;
                        margin: 0;
                        padding: 0;
                        color: rgba(0, 0, 0, .65);
                        font-size: 14px;
                        font-variant: tabular-nums;
                        line-height: 1.5715;
                        list-style: none;
                        font-feature-settings: "tnum";
                        position: relative;
                        z-index: 0;
                        clear: both;
                        background: #fff;
                        border-radius: 2px;

                        table {
                            width: 100%;
                            text-align: left;
                            border-radius: 2px 2px 0 0;
                            border-collapse: separate;
                            border-spacing: 0;
                        }

                        .ant-table-thead {
                            tr {
                                th {
                                    border: none;
                                    height: 40px;
                                    background: #f4f4f4;
                                }

                                th:first-child {
                                    border-right: 1px solid #ededed;
                                }

                                th:last-child {
                                    border-top-right-radius: 2px;
                                }
                            }
                        }

                        .ant-table-column-sorters {
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            font-size: 12px;
                            color: #323238;
                        }

                        .ant-table-column-sorter {
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            font-size: 12px;
                        }

                        .ant-table-column-sorter-full {
                            margin-top: -.2em;
                            margin-bottom: 0;
                        }

                        .ant-table-column-sorter-inner {
                            display: inline-flex;
                            flex-direction: column;
                            align-items: center;
                            margin: 0;
                        }

                        .ant-table-column-sorter-down, .ant-table-column-sorter-up {
                            display: inline-block;
                            font-size: 11px;
                        }

                        .ant-table-column-sorter-down.active, .ant-table-column-sorter-up.active {
                            color: $defaultColor;
                        }

                        .ant-table-column-sorter-up + .ant-table-column-sorter-down {
                            margin-top: -.3em;
                        }
                    }

                    .checkbox-wrapper {
                        margin-left: 1px;
                        border-right: 1px solid #ededed;
                        border-left: 1px solid #ededed;
                        width: 238px;

                        .ant-checkbox-group {
                            display: flex;
                            justify-content: center;
                        }

                        .ant-checkbox-wrapper {
                            box-sizing: border-box;
                            margin: 0;
                            padding: 0;
                            color: rgba(0, 0, 0, .65);
                            font-size: 14px;
                            font-variant: tabular-nums;
                            list-style: none;
                            font-feature-settings: "tnum";
                            display: inline-block;
                            line-height: unset;
                            cursor: pointer;
                        }

                        .ant-checkbox-wrapper {
                            padding: 0;
                            height: 40px;
                            border: none;
                            line-height: 40px;
                            color: #323238;
                            font-size: 12px;
                            margin: 0;


                        }
                    }

                    .right {
                        .pagination {
                            height: 40px;
                            display: flex;
                            align-items: center;
                            margin: 0;
                            padding: 0 8px;

                            .total {
                                margin-right: 8px;
                            }

                            .ant-btn {
                                height: 26px;
                                padding: 0;
                                color: #999;
                                border: 1px solid #ededed;
                                font-size: 12px;
                                width: 26px;
                                display: flex;
                                align-items: center;
                                justify-content: center;

                                .anticon {
                                    font-size: 14px;
                                    position: relative;
                                    top: 1px;
                                }
                            }
                        }
                    }
                }

                .other-query-second {
                    display: flex;
                    justify-content: space-between;
                    border: 1px solid #ededed;
                    width: 100%;
                    background: #f4f4f4;
                    font-size: 12px;
                    color: #323238;

                    ul {
                        display: flex;

                        li {
                            display: flex;
                            align-items: center;
                            height: 40px;
                            border-right: 1px solid #ededed;

                            .label {
                                margin-left: 20px;
                                margin-right: 13px;
                            }

                            .minimum {
                                height: 26px;
                                width: 60px;

                                input {
                                    padding: 0 7px;
                                    border: 1px solid #ededed;
                                    height: 26px;
                                    line-height: 26px;
                                    min-width: 60px;
                                }
                            }
                        }
                    }

                    .line {
                        width: 9px;
                        height: 1px;
                        background: #979797;
                        margin: 0 6px;
                    }

                    .supreme {
                        height: 26px;
                        width: 60px;
                        margin-right: 20px;

                        input {
                            padding: 0 7px;
                            border: 1px solid #ededed;
                            height: 26px;
                            line-height: 26px;
                            min-width: 60px;
                        }
                    }

                    .ant-btn {
                        height: 26px;
                        padding: 4px 9px;
                        font-size: 12px;
                        border: none;
                        background: linear-gradient(180deg, #fff, #e7e7e7);
                    }

                    .empty {
                        margin: 0 11px;
                        color: #ccabab;
                    }

                }
            }

        }

        .ant-spin-nested-loading {
            position: relative;
        }

        .ant-spin-container {
            position: relative;
            transition: opacity .3s;
        }

        .ant-row {
            display: flex;
            flex-flow: row wrap;
        }

        .ant-col-lg-8 {
            display: block;
            flex: 0 0 20%;
            max-width: 20%;
            padding-left: 25px;
            padding-right: 25px;
        }

        .ant-list-grid {
            .ant-col > .ant-list-item {
                display: block;
                max-width: 100%;
                margin-bottom: 16px;
                padding-top: 0;
                padding-bottom: 0;
                border-bottom: none;
            }
        }


        .goods-list-wrap {
            max-width: 1200px;
            min-width: 1200px;
            margin: 0 auto;
            /*min-height: 440px;*/

            .ant-list {
                width: 100%;

                * {
                    outline: none;
                }
            }


            .ant-card-bordered {
                background: #fff;

                box-shadow: 0 2px 8px 0 rgb(0 0 0 / 50%);
                border: none;
            }

            .ant-card-body {
                padding: 10px 10px 0;
            }

            .good-img {
                width: 100%;
                height: 198px;
                margin-bottom: 8px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .good-price {
                display: flex;
                align-items: baseline;
                color: $defaultColor;

                span {
                    font-size: 14px;
                    height: 28px;
                    line-height: 28px;
                    margin-right: 2px;
                }

                strong {
                    font-size: 20px;
                    font-weight: 400;
                    height: 28px;
                    line-height: 28px;
                }
            }

            .gross-profit {
                height: 17px;
                line-height: 17px;
                color: $defaultColor;
                font-size: 12px;
                font-weight: 400;
            }

            .good-desc {
                margin: 8px 0;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                word-break: break-all;
                color: #333;
                font-size: 13px;
                line-height: 18px;
                height: 36px;
            }

            .good-other {
                display: flex;
                justify-content: space-between;
                font-size: 12px;
                color: #999;
                height: 17px;
                line-height: 17px;

                .left {
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 83px;
                }
            }

            .ant-card-actions {
                background: #fff;
                border: 0 solid #e4e4e4;

                li {
                    float: left;
                    margin: 12px 0;
                    color: rgba(0, 0, 0, .45);
                    text-align: center;

                    > span {
                        position: relative;
                        min-width: 32px;
                        line-height: 22px;
                        cursor: pointer;
                    }
                }

                li.ng-star-inserted {
                    margin: 16px 0 13px;
                }

            }

            .action-box {
                width: 198px;
                margin: 0 auto;
                line-height: 38px;
                border: 1px solid #e4e4e4;
                font-size: 12px;
                color: $defaultColor;
                background-color: #fff;
                cursor: pointer;

                span {
                    vertical-align: middle;
                    margin-left: 4px;
                }
            }

            .action-active {
                background: #de0000;
                color: #fff;
                font-size: 12px;
                font-weight: bold;
            }
        }

        .pagination {
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 0 20px;
            margin-top: 15px;
        }


    }
</style>
