import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
// import './element-variables.scss'
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
import commonJs from './common'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.less';
import './ant-custom-css.less';
import * as echarts from 'echarts';
import axios from 'axios';
import ConfigProvider from 'ant-design-vue'
Vue.use(ConfigProvider)

import VueWechatTitle from 'vue-wechat-title'
Vue.use(VueWechatTitle)


Vue.config.productionTip = false;
Vue.prototype.$echarts = echarts;
Vue.prototype.$axios = axios;

Vue.prototype.$apiBaseURL =
    process.env.NODE_ENV === "production" //正式生产环境，程序最终发布后所需要的参数配置
        ? 'https://api.myhbei.com'
        : 'http://dev.myhb.com',

Vue.use(ElementUI);

Vue.use(ViewUI);
Vue.use(Antd);

Vue.use(commonJs)

axios.defaults.baseURL = 'http://dev.myhb.com/supplier/po';

new Vue({
  el:"#app",
  axios,
  router,
  render: h => h(App),
}).$mount('#app')
