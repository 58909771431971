<template>
    <div class="cdc-footer-wrap">
        <div>
            <div class="footer-wrap">
                <div class="list-wrap">
                    <div class="d-flex"><a class="d-flex"><img
                            src="../assets/img/footer/service1.png" class="pr-sm">正品行货 售后保障
                    </a><a class="d-flex"><img
                            src="../assets/img/footer/service2.png"
                            class="pr-sm">企业采购 专属价格 </a><a
                            class="d-flex"><img
                            src="../assets/img/footer/service3.png"
                            class="pr-sm">灵活付款 轻松采购 </a><a
                            class="d-flex"><img
                            src="../assets/img/footer/service4.png"
                            class="pr-sm">多仓发货 急速物流 </a></div>
                </div>
                <div class="nav-list-wrap">
                    <div class="d-left"></div>
                    <div class="d-right">
                        <div class="logo"><img
                                src="../assets/img/logo.png" class="pr-sm">
                        </div>
                    </div>
                </div>
                <div id="websiteCopyright" class="global-footer__copyright">Copyright ©
                    2019-2022 蚂蚁换呗（广州）科技有限公司 版权所有 All Rights
                    Reserved <a href="http://www.miit.gov.cn">粤ICP备2020102677号</a></div>
            </div>
        </div>
        <BackTop>
            <div class="ant-back-top ng-tns-c346-9 ng-trigger ng-trigger-fadeMotion ng-star-inserted">
                <button class="ant-btn ant-btn-primary ant-btn-icon-only ng-star-inserted" style=""><i

                        class="anticon anticon-vertical-align-top">
                    <svg viewBox="64 64 896 896" focusable="false" fill="currentColor" width="1em" height="1em"
                         data-icon="vertical-align-top" aria-hidden="true">
                        <path d="M859.9 168H164.1c-4.5 0-8.1 3.6-8.1 8v60c0 4.4 3.6 8 8.1 8h695.8c4.5 0 8.1-3.6 8.1-8v-60c0-4.4-3.6-8-8.1-8zM518.3 355a8 8 0 00-12.6 0l-112 141.7a7.98 7.98 0 006.3 12.9h73.9V848c0 4.4 3.6 8 8 8h60c4.4 0 8-3.6 8-8V509.7H624c6.7 0 10.4-7.7 6.3-12.9L518.3 355z"></path>
                    </svg>
                </i></button>
            </div>
        </BackTop>
    </div>
</template>

<script>
    export default {
        name: "cFooter"
    }
</script>

<style lang="scss" scoped>
    @import "../assets/css/common";
    .ivu-back-top {
        i {
            padding: 0;
            font-size: 16px;
        }
    }

    .nav-list-wrap {
        display: flex;

        .d-left {
            width: 80%;
            display: flex;
            justify-content: space-between;
        }

        .d-right {
            width: 20%;
            padding-top: 21px;

            .logo {
                display: flex;
                justify-content: flex-end;

                .pr-sm {
                    padding-right: 0 !important;
                }
            }
        }
    }
    .footer-wrap {
        max-width: 1200px;
        margin: 0 auto;

        .list-wrap {
            padding: 50px 0 48px;

            .d-flex {
                font-weight: 500;
                color: #333;
                line-height: 45px;
                font-size: 16px;
                justify-content: space-between;

                img {
                    padding-right: 10px !important;
                }
            }
        }
    }


</style>