import axios from "axios";
import {message} from "ant-design-vue";
import errorCode from './errorCode';
import Router from "../src/router/index";

axios.defaults.headers["Content-Type"] = "application/json;charset=utf-8";
// axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";

// 创建axios实例 axios.create([config])
const service = axios.create({
    // axios中请求配置有baseURL选项，表示请求URL公共部分
    baseURL:
            process.env.VUE_APP_BASE_API,
        // process.env.NODE_ENV === "production" //正式生产环境，程序最终发布后所需要的参数配置
        //     ? process.env.VUE_APP_BASE_API
        //     : process.env.VUE_APP_BASE_API,
    // 超时
    timeout: 30000
});
service.uri = {
    service:{
        upload: ''
    },
    user:{
        getUserInfo:'/v1/user/getUserInfo',
        saveUserInfo:'/v1/user/saveUserInfo',
        getLoginVerifyCode:'/v1/user/getLoginVerifyCode',
        getVerifyCode:'/v1/user/getVerifyCode',
        verifyPhone:'/v1/user/verifyPhone',
        setNewPhone:'/v1/user/setNewPhone',
        setNewPassword:'/v1/user/setNewPassword',
        setPayPassword:'/v1/user/setPayPassword',
        updateUsername:'/v1/user/updateUsername',
        getBuyerIndex:'/v1/user/getBuyerIndex',
        getAdvance:'/v1/user/getAdvance',
        hasPayPassword:'/v1/user/hasPayPassword',
        setBalanceWarning:'/v1/user/setBalanceWarning',
        fileLog:'/v1/user/fileLog',
        download:'/v1/user/download',
    },
    company:{
        getList: '/v1/company/getList',
        getInfo: '/v1/company/getInfo',
        add: '/v1/company/add',
        updateInfo: '/v1/company/updateInfo',
        getRegionByParent: '/v1/company/getRegionByParent',
    },
    passport:{
        login:'/v1/passport/login',
        getLoginVerifyCode:'/v1/passport/getLoginVerifyCode',
        verifyCodeLogin:'/v1/passport/verifyCodeLogin'
    },
    bankAccount:{
        add:'/v1/bank-account/add',
        getRegion:'/v1/bank-account/getRegion',
        getList:'/v1/bank-account/getList'
    },
    pay:{
        orderMainPay: '/v1/pay/orderMainPay',
        getBankInfo: '/v1/pay/getBankInfo',
        withdrawal: '/v1/pay/withdrawal',
        balanceLog: '/v1/pay/balanceLog',
        rechargeList: '/v1/pay/rechargeList',
        getWithdrawalList: '/v1/pay/getWithdrawalList',
    },
    address:{
        add: '/v1/address/add',
        getList: '/v1/address/getList',
        del: '/v1/address/del',
        edit: '/v1/address/edit',
    },
    invoice:{
        getInfo: '/v1/invoice/getInfo',
        modify: '/v1/invoice/modify',
        pendingSettle: '/v1/invoice/pendingSettle',
        getLog: '/v1/invoice/getLog',
        apply: '/v1/invoice/apply',
        getBillDetail: '/v1/invoice/getBillDetail',
        getGoodsList: '/v1/invoice/getGoodsList',
        getExpressFeeList: '/v1/invoice/getExpressFeeList',
    },
    order:{
        getList:'/v1/order/getList',
        getInfo:'/v1/order/getInfo',
        quickOrderFile:'/v1/order/quickOrderFile',
        stat:'/v1/order/stat',
        goodsStat:'/v1/order/goodsStat',
        getPurchaseDataHour:'/v1/order/getPurchaseDataHour',
        listPurchaseDataHour:'/v1/order/listPurchaseDataHour',
        getRefundType:'/v1/order/getRefundType',
        applyRefund:'/v1/order/applyRefund',
        buyerOrderOverall:'/v1/order/buyerOrderOverall',
        quickOrder:'/v1/order/quickOrder',
        refundList:'/v1/order/refundList',
        refundDetail:'/v1/order/refundDetail',
    },
    goods:{
        getList: '/v1/goods/getList',
        addToMyLib: '/v1/goods/addToMyLib',
        myLib: '/v1/goods/myLib',
        removeFromMyLib: '/v1/goods/removeFromMyLib',
        getCategoryTree: '/v1/goods/getCategoryTree',
        getMyLibCount: '/v1/goods/getMyLibCount',
        goodsCateStat:'/v1/goods/goodsCateStat',
        goodsSaleStat:'/v1/goods/goodsSaleStat',
        getInfo:'/v1/goods/getInfo',
    },
    document:{
        getDocument:'/v1/document/getDetail'
    }
};
// request拦截器
service.interceptors.request.use(
    config => {
        // 是否需要设置 token
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        if(userInfo){
            config.headers["tenantId"] = userInfo.tid; // 让每个请求携带自定义token 请根据实际情况自行修改
            config.headers["token"] = userInfo.token; // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        return config;
    },
    error => {
        console.log(error);
        Promise.reject(error);
    }
);

// 响应拦截器
service.interceptors.response.use(
    res => {
        // 未设置状态码则默认成功状态
        const code = res.data.code || 200;
        // 获取错误信息
        const msg = errorCode[code] || res.data.info || errorCode['default'];
        if (code === 403) {
            // 跳转登录页
            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
            message.error('登录状态已过期，请重新登录');
            Router.push(
                '/passport/login',
                () => {},
                e => {
                    console.log(e)
                }
            );
        } else if (code === 500) {
            message.error(msg);
            return res.data;
        } else if (code !== 200) {
            message.error(msg);
            return res.data;
        } else {
            return res.data;
        }
    },
    error => {
        console.log('err' + error);
        let {msg} = error;
        if (msg == 'Network Error') {
            msg = '后端接口连接异常';
        } else if (msg.includes('timeout')) {
            msg = '系统接口请求超时';
        } else if (message.includes('Request failed with status code')) {
            msg = '系统接口' + msg.substr(msg.length - 3) + '异常';
        }
        message.error(msg)
        return Promise.reject(error);
    }
);
export default service;