import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '供应链云',
        }
    },
    {path:'/download', name:"Download", component: () => import('../views/download')},
    {
        path: '/opc',
        name: 'Opc',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/opc.vue'),
        meta:{title:"API服务"},
        children: [
            {
                path:'api-service',
                component: () => import('../views/opc/api-service'),
                meta:{title:"API服务"},
            },
            {
                path:'document',
                component: () => import('../views/opc/document'),
                meta:{title:'文档中心'}
            }
        ]
    },
    {
        path: '/goods/:id/type/:type',
        name: 'Goods',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/goods.vue'),
        meta:{title:"商品详情"}
    },
    {
        path: '/atc',
        name: 'Atc',
        component: () => import("../views/atc"),
        children:[
            {
                path:'account',
                component: () => import('../views/atc/account'),
                meta:{title:"帐号设置"}
            },
            {
                path:'mdyUserInfo',
                component: () => import('../views/atc/mdyUserInfo'),
                meta:{title:"修改个人信息"}
            },
            {
                path:'my-phone',
                component: () => import('../views/atc/my-phone'),
                meta:{title:"修改手机号"}
            },
            {
                path:'myd-pwd',
                component: () => import('../views/atc/mdy-pwd'),
                meta:{title:"修改登录密码"}
            },
            {
                path:'attestation',
                component: () => import('../views/atc/attestation'),
                meta:{title:"角色管理"}
            },
            {
                path:'attestation-add',
                component: () => import('../views/atc/attestation-add'),
                meta:{title:"添加角色"}
            }
        ]
    },
    {
        path:"/passport",
        name:'Passport',
        component: () => import('../views/passport'),
        children: [
            {
                path: 'login',
                component: () => import('../views/passport/login'),
                meta:{title:"登录"}
            },
            {
                path: 'retrieve-password',
                component: () => import('../views/passport/retrieve-password'),
                meta:{title:"重置密码"}
            }
        ]
    },
    {
        path:'/buyer-detail',
        name:'buyer-detail',
        component: () => import('../views/buyer-detail'),
        children:[
            {
                path:"buyer-setting-payment-password",
                name:"payment-password",
                component: () => import("../views/buyer-detail/buyer-setting-payment-password"),
                meta:{title:"支付密码"}
            },
            {
                path:"order/:id/type/:type",
                name:"order",
                component: () => import("../views/buyer-detail/order"),
                meta:{title:"订单详情"}
            },
        ]
    },
    {
        path: '/buyer',
        name: 'Buyer',
        component: () => import('../views/buyer'),
        children:[
            {
                path:'index',
                component: () => import('../views/buyer/index.vue'),
                meta:{title:"买家中心"}
            },
            {
                path:'order-detail',
                component: () => import('../views/buyer/order-detail'),
                meta:{title:"订单详情"}
            },
            {
                path:'order-list',
                component: () => import('../views/buyer/order-list'),
                meta:{title:"订单管理"}
            },
            {
                path:'quick-order',
                component: () => import('../views/buyer/quick-order'),
                meta:{title:"快速下单"}
            },
            {
                path:'refund-list',
                component: () => import('../views/buyer/refund-list'),
                meta:{title:"退货/售后"}
            },
            {
                path:'refund-detail/:id',
                component: () => import('../views/buyer/refund-detail'),
                meta:{title:"售后详情"}
            },
            {
                path:'goods-offer',
                component: () => import('../views/buyer/goods-offer'),
                meta:{title:"商品变价记录"}
            },
            {
                path:'my-choose',
                component: () => import('../views/buyer/my-choose'),
                meta:{title:"我的选品库"}
            },
            {
                path:'my-goods',
                component: () => import('../views/buyer/my-goods'),
                meta:{title:"我的商品库"}
            },
            {
                path:'property-bank',
                component: () => import('../views/buyer/property-bank'),
                meta:{title:"银行帐户"}
            },
            {
                path:'property-invoice',
                component: () => import('../views/buyer/property-invoice'),
                meta:{title:"发票管理"}
            },
            {
                path:'property-prepay',
                component: () => import('../views/buyer/property-prepay'),
                meta:{title:"预付款"}
            },
            {
                path:'board',
                component: () => import('../views/buyer/board'),
                meta:{title:"数据看板"}
            },
            {
                path:'situation',
                component: () => import('../views/buyer/situation'),
                meta:{title:"实时概览"}
            },
            {
                path:'setting-profile',
                component: () => import('../views/buyer/setting-profile'),
                meta:{title:"企业档案"}
            },
            {
                path:'setting-invoice',
                component: () => import('../views/buyer/setting-invoice'),
                meta:{title:"发票抬头"}
            },
            {
                path:'setting-address',
                component: () => import('../views/buyer/setting-address'),
                meta:{title:"收货地址"}
            },
            {
                path:'setting-safety',
                component: () => import('../views/buyer/setting-safety'),
                meta:{title:"安全中心"}
            },
            {
                path:'setting-msg',
                component: () => import('../views/buyer/setting-msg'),
                meta:{title:"消息通知"}
            },
            {
                path:'bill/:id',
                component: () => import('../views/buyer/bill'),
                meta:{title:"帐单周期明细"}
            }
        ]
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
