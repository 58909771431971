<template>
<div class = "warp">
            <div class = "user-warp">
                <div class = "nickname" :class = "headerWarpActive" @mouseover = "headerWarpMouseOver()"
                     @mouseleave = "headerWarpMouseLeave()">
                    <div class = "nickname-show">{{userInfo.nickname}} <img class = "arrow_b"
                                                            :class = "headerWarpActive ? 'arrow_t' : ''"
                                                            src = "../assets/img/arrow_b.png" alt = ""></div>
                    <div class = "user-info">
                        <div class = "info">
                            <img class = "avatar" src = "../assets/img/user-avatar-default.png" alt = "">
                            <div class = "user-account-info">
                                <p>{{userInfo.nickname}}</p>
                                <p>{{userInfo.mobile}}</p>
                            </div>
                            <div class = "logout" @click="logout">退出</div>
                        </div>
                        <div class = "account">
                            <div class = "account-setting" @click="() => {$router.push('/atc/account')}">帐户设置</div>
                            <div class = "my-company">我的企业</div>
                        </div>
                    </div>
                </div>
                <div class = "company-name">
                    <div class = "company-name-show">{{userInfo.name}}</div>
                </div>
                <div class = "header-nav">
                    <ul class = "nav">
                        <li class = "header-nav-item" @click = "$jumpTo('/')">首页</li>
                        <li class = "header-nav-item" :class = "buyerCenterActive"
                            @mouseover = "buyerCenterMouseOver()"
                            @mouseleave = "buyerCenterMouseLeave()">
                            <div @click = "$jumpTo('/buyer/index')">买家中心<img class = "arrow_b"
                                                                             :class = "buyerCenterActive ? 'arrow_t' : ''"
                                                                             src = "../assets/img/arrow_b.png" alt = ""></div>
                            <ul class = "header-nav-sub buyer-center-op">
                                <li @click="$jumpTo('/buyer/order-list')">订单列表</li>
                                <li @click="$jumpTo('/buyer/refund-list')">退货/售后</li>
                                <li @click="$jumpTo('/buyer/my-goods')">选品管理</li>
                            </ul>
                        </li>
                        <li class = "header-nav-item">
                            消息 <span class = "badge" v-if="msgNum > 0">{{msgNum}}</span>
                        </li>
                        <li class="header-nav-item" @click="$jumpTo('/opc/api-service')">API服务</li>
                    </ul>
                </div>
            </div>

        </div>
</template>

<script>
export default {
  name: "top",
  data() {
    return {
      headerWarpActive: '',
      buyerCenterActive: '',
        userInfo: {},
        msgNum:0
    }
  },
    created() {
      let user = JSON.parse(localStorage.getItem('userInfo'));
      if(!user){
          this.$message.error('请先登录');
          this.$router.push('/passport/login');
          return false;
      }
      this.userInfo = user;
    },
    methods: {

    headerWarpMouseOver() {
      console.log('over');
      this.headerWarpActive = 'selected'
    },
    headerWarpMouseLeave() {
      this.headerWarpActive = ''
    },
    buyerCenterMouseOver() {
      this.buyerCenterActive = 'selected'
    },
    buyerCenterMouseLeave() {
      this.buyerCenterActive = ''
    },
        logout(){
            localStorage.clear();
            this.$router.push('/passport/login');
        },
  }
}
</script>

<style lang="scss" scoped>
    @import "../assets/css/common";

.warp {
  background: #f0f0f0;
  font-size: 12px;
  color: #666;
  height: 38px;
  line-height: 38px;
  margin: auto;

  .user-warp {

    width: 1200px;
    margin: auto;
  }

  .nickname {
    float: left;

    .nickname-show {
      padding: 0 20px;
    }
  }

  .company-name {
    float: left;

    .company-name-show {
      padding: 0 20px;
    }
  }

  .header-nav {
    float: right;
    /*min-width: 300px;*/
    li{
      cursor: pointer;
    }
    .header-nav-item {
      list-style: none;
      float: left;
      width: 80px;
      text-align: center;

      .header-nav-sub {
        position: absolute;
        z-index: 2;
        width: 80px;
        box-shadow: 2px 2px 10px #f0f0f0;

        background-color: #fff;

        li {
          list-style: none;
        }
      }
    }
  }

  .arrow_b {
    position: relative;
    top: -2px;
  }

  .arrow_t {
    transform: rotate(180deg);
  }

  .selected {
    background: #fff;
  }

  .badge {
    background-color: $defaultColor;
    color: #fff;
    padding: 0 5px;
    border-radius: 10px;
  }

  .selected .user-info, .selected .buyer-center-op {
    display: block;
  }

  .user-info, .buyer-center-op {
    display: none;
  }

  .user-info {
    position: absolute;
    z-index: 2;
    padding: 15px;
    box-shadow: 2px 2px 10px #f0f0f0;
    background-color: #fff;

    .info {
      overflow: hidden;
    }

    .avatar {
      width: 30px;
      height: 30px;
      float: left;
    }

    .user-account-info {
      margin: 0 15px;
      float: left;
      line-height: 18px;
    }

    .logout {
      float: left;
      line-height: 36px;
      border-left: 1px solid #f0f0f0;
      padding-left: 10px;
      color: $defaultColor;
        cursor: pointer;
    }

    .account {
      overflow: hidden;
      margin-top: 15px;
      line-height: 20px;
      display: flex;
      justify-content: space-between;

      .account-setting, .my-company {
        float: left;
          cursor: pointer;
      }
    }
  }
}
</style>