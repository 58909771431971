<template>
    <div class="header">
        <cTop></cTop>
        <div class="warp-search">
            <div class="header-search">
                <div class="search-logo" @click="$jumpTo('/')"><img src="../assets/img/logo.png" alt=""></div>
                <div class="search-box">
                    <div class="d-flex">
                        <div class="left">
                            <input type="text" v-model="searchKeyword" placeholder="商品名称/商品编号">
                        </div>
                        <div class="right">
                            <button class="ant-btn"  @click="search">
                                <span>搜索</span>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="search-goods">
                    <a href="/#/buyer/my-goods" class="goods-box">
                        <img src="../assets/img/myGoods.png" alt="">
                        <strong>我的商品库</strong>
                        <span class="badge">{{ myLibCount }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import cTop from './top';
import service from "../../utils/request";
    export default {
        name: "cHeader",
      components:{
          cTop
      },
        props:{
            keyword: {
                type: String,
                default:''
            }
        },
        data(){
            return {
                myLibCount:0,
                searchKeyword:''
            }
        },
        watch:{
            keyword(nv){
                this.searchKeyword = nv;
            }
        },
        created() {
            this.searchKeyword = this.keyword;
            this.getMyLibCount();
        },
        methods:{

            getMyLibCount(){
                service.post(service.uri.goods.getMyLibCount).then(res => {
                    if(res.code == 200){
                        this.myLibCount = res.data
                    }
                });
            },
            search(){
                if(this.$route.name != 'Home'){
                    this.$router.push({name: 'Home', params: {keyword: this.searchKeyword}});
                    return;
                }
                this.$emit('search', this.searchKeyword);
            }
        }
    }
</script>

<style lang="scss" scoped>

    @import "../assets/css/common";
    .header {
        overflow: hidden;
        min-width: 1200px;


        .warp-search {
            position: relative;
            z-index: 1;
            background: #fff;
            box-shadow: inset -1px 0px 5px -3px rgb(0 0 0 / 30%);


            .header-search {
                width: 1200px;
                margin: auto;
                overflow: hidden;
                display: flex;
                cursor: pointer;

                .search-logo, .search-box {
                    height: 105px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }

                .search-box {
                    font-size: 14px;
                    width: 452px;
                    margin-left: 116px;

                    .d-flex {
                        flex: 1;
                        height: 40px;
                        border-radius: 25px;
                        background: #f4f4f4;
                        display: flex;
                        justify-content: space-between;
                    }

                    .left {
                        height: 40px;
                        display: flex;
                        align-items: center;
                        flex: 1;
                        padding: 0 25px;

                        input {
                            width: 100%;
                            border: none;
                            background-color: #f4f4f4;
                            outline: none;
                        }
                    }

                    .right {
                        width: 79px;
                        text-align: center;
                        color: #fff;

                        .ant-btn {
                            width: 79px;
                            height: 40px;
                            background: $defaultColor;
                            border: 1px solid transparent;
                            border-top-right-radius: 25px;
                            border-bottom-right-radius: 25px;

                        }

                        span {
                            font-size: 14px;
                            font-weight: 400;
                            color: #fff;
                        }
                    }
                }

                .search-goods {
                    height: 105px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    font-size: 14px;
                    margin-left: 51px;

                    .goods-box {
                        min-width: 178px;
                        height: 40px;
                        border-radius: 25px;
                        border: 1px solid #ededed;
                        display: flex;
                        justify-content: center;
                        align-items: center;

                        strong {
                            margin: 0 4px 0 6px;
                            color: #323238;
                            font-style: normal;
                            font-weight: 400;
                        }

                        span {
                            padding: 0 8px;
                            height: 16px;
                            line-height: 16px;
                            border-radius: 8px;
                            background: #323238;
                            font-size: 12px;
                            color: #fff;
                        }
                    }
                }
            }
        }
    }

</style>