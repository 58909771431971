<template>
    <div class="sub-nav-wrap">
        <div class="nav-menu">
            <ul>
                <li class="goods-classify">
                    <i class="action anticon-bars">
                        <Icon type="ios-list" size="18"/>
                    </i>
                    <span>商品分类</span>
                </li>
            </ul>
        </div>
        <div class="class-list">
            <div class="content">
                <ul>
                    <li class="li"
                        v-for="(item, idx) in classList" :key="idx"
                        @mouseover="addClassListClass(idx)"
                        @mouseleave="rmClassListClass(idx)"
                        :class="classListHover == true && classListHoverCurrent == idx ? 'hover' : ''"
                        @click="changeFirstCate(idx)"
                    > {{item.title}}
                        <div class="app-list">
                            <div class="app-list-content">
                                <dl v-for="(subClass, subIdx) in item.children" :key="subIdx">
                                    <dt @click.stop="changeSecondCate(idx, subIdx)"><strong>{{subClass.title}}</strong></dt>
                                    <dd v-for="(thirdClass, thirdIdx) in subClass.children" :key="thirdIdx">
                                        {{thirdClass.title}}
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import service from "../../utils/request";

    export default {
        name: "cMenu",
        data(){
            return {

                classListHover: false,
                classListHoverCurrent: 0,
                classList: [
                    {
                        title: '其他',
                        children: [
                            {
                                title: "影音综艺",
                                children: [
                                    {
                                        title: '综艺',
                                    },
                                    {
                                        title: '其他',
                                    },
                                    {
                                        title: '电影',
                                    },
                                    {
                                        title: '电视剧',
                                    },
                                    {
                                        title: '综艺',
                                    },
                                    {
                                        title: '其他',
                                    },
                                    {
                                        title: '电影',
                                    },
                                    {
                                        title: '电视剧',
                                    },
                                    {
                                        title: '综艺',
                                    },
                                    {
                                        title: '其他',
                                    },
                                    {
                                        title: '电影',
                                    },
                                    {
                                        title: '电视剧',
                                    },
                                ]
                            },
                            {
                                title: "其他"
                            },
                            {
                                title: "名人明星"
                            },
                            {
                                title: "文体活动"
                            },
                        ]
                    },
                    {
                        title: '家居日用',
                    },
                    {
                        title: '二手商品',
                    },
                    {
                        title: '京东服务',
                    },
                    {
                        title: '个人护理',
                    },
                    {
                        title: '家庭清洁/纸',
                    },
                    {
                        title: '非遗',
                    },
                    {
                        title: '生鲜',
                    },
                    {
                        title: '整车',
                    },
                    {
                        title: '教育培训',
                    },
                    {
                        title: '农资园艺',
                    },
                    {
                        title: '酒类',
                    },
                    {
                        title: '鞋靴',
                    },
                    {
                        title: '团购',
                    },
                ],
            }
        },
        created() {
            this.getCategoryTree();
        },
        methods:{

            addClassListClass(idx) {
                this.classListHover = true;
                this.classListHoverCurrent = idx;
            },
            rmClassListClass(idx) {
                this.classListHover = false;
                this.classListHoverCurrent = idx;
            },

            getCategoryTree(){
                service.post(service.uri.goods.getCategoryTree).then(res => {
                    if(res.code == 200){
                        this.classList = res.data.list;
                    }
                })
            },
            changeFirstCate(idx){

                if(this.$route.name != 'Home'){
                    this.$router.push({name: 'Home', params: {firstCate: idx}});
                    return;
                }
                this.$emit('changeFirstCate', idx);
            },
            changeSecondCate(idx, subIdx){
                if(this.$route.name != 'Home'){
                    this.$router.push({name: 'Home', params: {subCate: [idx, subIdx]}});
                    return;
                }
                this.$emit('changeSecondCate', idx, subIdx);
            },
        }
    }
</script>

<style lang="scss" scoped>
 @import "../assets/css/common";

 .sub-nav-wrap {
     width: 100%;

 }

 .nav-menu {
     max-width: 1200px;
     height: 70px;
     margin: 0 auto;
     padding-top: 30px;
     font-size: 16px;
     font-weight: 400;
     cursor: pointer;
     display: flex;
     justify-content: space-between;

     li {
         height: 40px;
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 0 32px;
     }

     .goods-classify {
         width: 158px;
         background: #323238;
         font-size: 14px;
         color: #fff;
     }

 }

 .class-list {
     cursor: pointer;
     height: 40px;
     background: #323238;
     font-size: 12px;
     font-weight: 400;
     color: #fff;

     .content {
         max-width: 1200px;
         margin: 0 auto;
         position: relative;
         z-index: 99;
     }

     ul {
         display: flex;
         justify-content: space-between;
     }

     li {
         height: 40px;
         line-height: 40px;
         text-align: center;
         margin: 0 10px;
     }

     .app-list {
         display: none;

         background-color: #fff;
         position: absolute;
         z-index: 2;
         left: 0;
         width: 1200px;
         /*height: 300px;*/
         padding-bottom: 30px;
         /*box-shadow: inset 2px -2px 5px 2px rgb(0 0 0 / 10%);*/
         /*box-shadow: inset 0 1px 5px 1px #000;*/
         border: 1px solid #fff;
         border-top: 0;
         box-shadow: 0px 0px 5px -5px rgb(0 0 0 / 10%),
         0px -1px 15px 0px rgb(0 0 0 / 10%),
         0px -1px 15px 0px rgb(0 0 0 / 10%),
         0px -1px 15px 0px rgb(0 0 0 / 10%);

         color: #000;

         .app-list-content {
             display: flex;
             justify-content: left;
             padding-top: 20px;
             /*height: 300px;*/
             overflow-y: scroll;

             dl {
                 width: 133px;
                 text-align: center;

                 dt {
                     font-size: 18px;
                 }

                 dd {
                     font-size: 14px;
                 }
             }
         }
     }

     .hover {
         background: url("../assets/img/arrow_t_white.png") no-repeat bottom/11px 7px;

         .app-list {
             display: block;
         }
     }
 }

</style>